<template>
  <CCard style="margin-top: 20px">
    <CCardHeader class="bg-warning text-white"
      ><strong>บันทึกการคัดกรอง COVID-19 ด้วยตนเอง</strong>
    </CCardHeader>
    <CCardBody v-if="$store.state.screeningLogList">
      <form
        id="formGetAppByDate"
        v-on:submit.prevent="getAppByDate"
        method="POST"
      >
        <CRow style="margin-bottom: 20px">
          <CCol lg="4"> </CCol>
          <CCol lg="4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ตั้งแต่วันที่:
              </label>
              <datepicker
                name="startDate"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="เลือกวันเริ่มต้นที่ต้องการ"
                v-model="selectedStartDate"
              ></datepicker>
            </div>
          </CCol>
          <CCol lg="4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                จนถึงวันที่:
              </label>
              <datepicker
                name="endDate"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="เลือกวันสุดท้ายที่ต้องการ"
                v-model="selectedEndDate"
              ></datepicker>
            </div>
            <div class="text-right">
              <button type="submit" class="btn btn-dark">ค้นหา</button>
            </div>
          </CCol>
        </CRow>
      </form>
      <vue-good-table
        :columns="columns"
        :rows="$store.state.teachingLogList"
        :line-numbers="true"
        :search-options="{ enabled: true }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'teaching_log_id', type: 'desc' },
        }"
        :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
      >
        >
        <div slot="table-actions">
          <button
            class="btn btn-primary"
            style="margin-right: 10px"
            @click="openModal"
          >
            +add
          </button>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <CDropdown>
              <template #toggler>
                <CHeaderNavLink>
                  <button class="btn btn-secondary btn-block">Tools</button>
                </CHeaderNavLink>
              </template>
              <CDropdownItem>
                <a
                  class="text-center"
                  style="width: 100%"
                  @click="
                    $router.push({
                      path: `/teachingLogView/${props.row.teaching_log_id}`,
                    })
                  "
                >
                  view
                </a>
              </CDropdownItem>
              <CDropdownItem
                v-if="props.row.teaching_user_id == parseInt(userData.user_id)"
              >
                <div
                  class="text-center"
                  style="width: 100%"
                  @click="openModalEdit(props.row.teaching_log_id)"
                >
                  edit
                </div>
              </CDropdownItem>
              <CDropdownItem
                class="bg-danger text-white"
                v-if="props.row.teaching_user_id == parseInt(userData.user_id)"
              >
                <div
                  class="text-center"
                  style="width: 100%"
                  @click="removeLog(props.row.teaching_log_id)"
                >
                  delete
                </div>
              </CDropdownItem>
            </CDropdown>
          </span>
          <span v-else-if="props.column.field == 'improve_name'"> </span>
          <span v-else-if="props.column.field == 'teaching_std_missing'">
            <div
              v-for="(std, n) in JSON.parse(props.row.teaching_std_missing)"
              :key="std.id"
            >
              {{ n + 1 }}) {{ std.label }}
            </div>
          </span>
          <span v-else-if="props.column.field == 'pictures'">
            <img
              v-for="pic in JSON.parse(props.row.teaching_pictures)"
              :key="pic.id"
              :src="pic"
              width="100%"
            />
          </span>
          <span v-else-if="props.column.field == 'teaching_who_comment'">
            <span class="badge badge-secondary" v-if="props.row.teaching_who_comment == 0">รอการนิเทศ</span>
            <span class="badge badge-success" v-else>นิเทศโดย {{props.row.teaching_who_comment}}</span>
            
          </span>
        </template>
      </vue-good-table>
      <CRow style="margin-top: 20px">
        <CCol
          ><div style="text-align: right">
            <button @click="onExport" class="btn btn-secondary">Export</button>
            <!-- เพิ่มปุ่ม Export -->
          </div></CCol
        >
      </CRow>
    </CCardBody>
    <CCardBody v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only"></span>
      </div>
      <div>Loading...</div>
    </CCardBody>
    <!-------------------MODAL CREATE--------------------------------------------------------------------------------------->
    <form id="formCreateLog" v-on:submit.prevent="createLog" method="POST">
      <CModal
        :show.sync="modalCreate"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow
          ><!--body-->

          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสผู้รายงาน
              </label>
              <input
                type="text"
                name="txtUserId"
                class="form-control"
                readonly
                :value="userData.user_id"
              /></div
          ></CCol>
          <CCol lg="6" v-if="levelInfo">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ชั้นที่สอน:
              </label>
              <select name="sltLevel" class="form-control" required>
                <option value="">-----เลือก-----</option>
                <option
                  v-for="level in levelInfo"
                  :key="level.id"
                  :value="level.level_id"
                >
                  {{ level.level_fullName }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="6" v-if="levelInfo">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ห้อง/ กลุ่ม:
              </label>
              <select name="sltClass" class="form-control" required>
                <option value="">-----เลือก-----</option>
                <option
                  v-for="classNo in 17"
                  :key="classNo.id"
                  :value="classNo"
                >
                  {{ classNo }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="12">
            <SltSubject />
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                วันที่
              </label>
              <datepicker
                name="txtDate"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="วันที่จัดกิจกรรม"
              ></datepicker>
            </div>
          </CCol>
          <CCol lg="6">
            <ChkPeriod :modalType="'create'" />
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนทั้งหมด (คน)
              </label>
              <input
                type="number"
                name="numStdAmount"
                class="form-control"
                required
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่เข้าเรียน (คน)
              </label>
              <input
                type="number"
                name="numStdAttended"
                class="form-control"
                required
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่ขาด/ ลา
              </label>
              <SltStudent /></div
          ></CCol>
          <CCol lg="6" v-if="routeInfo">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ช่องทางการสอน:
              </label>
              <select name="sltRoute" class="form-control" required>
                <option value="">-----เลือก-----</option>
                <option
                  v-for="route in routeInfo"
                  :key="route.id"
                  :value="route.route_id"
                >
                  {{ route.route_name }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="12" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ปัญหา หรือข้อเสนอแนะ:
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlText1"
                name="areaSuggest"
                rows="3"
              ></textarea></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                <strong>ภาพประกอบรายงาน:</strong>
              </label>
              <div
                v-for="n in 4"
                :key="n.id"
                class="bg-secondary el-shadow text-black"
                style="padding: 20px; margin-bottom: 5px; border-radius: 5px"
              >
                <form
                  :id="'formPic' + n"
                  v-on:submit.prevent="uploadFile"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="exampleFormControlFile1"
                      >Upload ภาพที่ {{ n }}:</label
                    >
                    <input
                      type="file"
                      class="form-control-file form-control-sm"
                      :id="'exampleFormControlFile' + n"
                      :name="'file' + n"
                      required
                    />
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-warning btn-sm">
                      upload
                    </button>
                  </div>
                </form>
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 1:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl1"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 2:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl2"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 3:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl3"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 4:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl4"
                />
              </div>
            </div>
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                <strong>ไฟล์ประกอบรายงาน:</strong>
              </label>
              <div
                v-for="o in 8"
                :key="o.id"
                class="bg-yellow el-shadow text-black"
                style="padding: 20px; margin-bottom: 5px; border-radius: 5px"
                v-if="o > 4"
              >
                <form
                  :id="'formFile' + o"
                  v-on:submit.prevent="uploadFile"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="exampleFormControlFile1"
                      >Upload ไฟล์ที่ {{ o - 4 }}:</label
                    >
                    <input
                      type="file"
                      class="form-control-file form-control-sm"
                      :id="'exampleFormControl' + o"
                      :name="'file' + o"
                      required
                    />
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-warning btn-sm">
                      upload
                    </button>
                  </div>
                </form>
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 1:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink1"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 2:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink2"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 3:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink3"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 4:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink4"
                />
              </div></div
          ></CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">
              บันทึกการสอนของ: {{ userData.user_fname }}
            </h3>
          </h6>
          <CButtonClose @click="modalCreate = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalCreate = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-info">Save</button>
        </template>
      </CModal>
    </form>
    <!-------------------MODAL EDIT--------------------------------------------------------------------------------------->
    <form id="formUpdateLog" v-on:submit.prevent="updateLog" method="POST">
      <CModal
        :show.sync="modalEdit"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="danger"
      >
        <CRow v-if="$store.state.selectedTeachingLog"
          ><!--body-->
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                #
              </label>
              <input
                type="text"
                name="txtLogId"
                class="form-control"
                readonly
                :value="$store.state.selectedTeachingLog.teaching_log_id"
              />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสผู้แก้ไข
              </label>
              <input
                type="text"
                name="txtUserId"
                class="form-control"
                readonly
                :value="userData.user_id"
              /></div
          ></CCol>

          <CCol lg="6" v-if="levelInfo">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ชั้นที่สอน:
              </label>
              <select
                name="sltLevel"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_std_level"
              >
                <option value="">-----เลือก-----</option>
                <option
                  v-for="level in levelInfo"
                  :key="level.id"
                  :value="level.level_id"
                >
                  {{ level.level_fullName }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="6" v-if="levelInfo">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ห้อง/ กลุ่ม:
              </label>
              <select
                name="sltClass"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_std_class"
              >
                <option value="">-----เลือก-----</option>
                <option
                  v-for="classNo in 17"
                  :key="classNo.id"
                  :value="classNo"
                >
                  {{ classNo }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="12">
            <SltSubject />
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                วันที่
              </label>
              <datepicker
                name="txtDate"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="วันที่จัดกิจกรรม"
                v-model="$store.state.selectedTeachingLog.teaching_date"
              ></datepicker>
            </div>
          </CCol>
          <CCol lg="6">
            <ChkPeriod :modalType="'edit'" />
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนทั้งหมด (คน)
              </label>
              <input
                type="number"
                name="numStdAmount"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_std_amount"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่เข้าเรียน (คน)
              </label>
              <input
                type="number"
                name="numStdAttended"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_std_attended"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่ขาด/ ลา
              </label>
              <SltStudent /></div
          ></CCol>
          <CCol lg="6" v-if="routeInfo">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ช่องทางการสอน:
              </label>
              <select
                name="sltRoute"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_route"
              >
                <option value="">-----เลือก-----</option>
                <option
                  v-for="route in routeInfo"
                  :key="route.id"
                  :value="route.route_id"
                >
                  {{ route.route_name }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="12" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ปัญหา หรือข้อเสนอแนะ:
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                name="areaSuggest"
                rows="3"
                v-model="$store.state.selectedTeachingLog.teaching_problem"
              ></textarea></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                <strong>ภาพประกอบรายงาน</strong>
              </label>
              <div
                v-for="n in 4"
                :key="n.id"
                class="bg-secondary el-shadow text-black"
                style="padding: 20px; margin-bottom: 5px; border-radius: 5px"
              >
                <form
                  :id="'formUpdatePic' + n"
                  v-on:submit.prevent="editFile"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="exampleFormControlFile1"
                      >Upload ภาพที่ {{ n }}:</label
                    >
                    <input
                      type="file"
                      class="form-control-file form-control-sm"
                      :id="'exampleFormControlFile' + n"
                      :name="'file' + n"
                      required
                    />
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-warning btn-sm">
                      upload
                    </button>
                  </div>
                </form>
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link1:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic1New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link2:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic2New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link3:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic3New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link4:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic4New"
                />
              </div></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                <strong>ไฟล์ประกอบรายงาน:</strong>
              </label>
              <div
                v-for="o in 8"
                :key="o.id"
                class="bg-yellow el-shadow text-black"
                style="padding: 20px; margin-bottom: 5px; border-radius: 5px"
                v-if="o > 4"
              >
                <form
                  :id="'formUpdateFile' + o"
                  v-on:submit.prevent="editFile"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="exampleFormControlFile1"
                      >Upload ไฟล์ที่ {{ o - 4 }}:</label
                    >
                    <input
                      type="file"
                      class="form-control-file form-control-sm"
                      :id="'exampleFormControl' + o"
                      :name="'file' + o"
                      required
                    />
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-warning btn-sm">
                      upload
                    </button>
                  </div>
                </form>
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link1:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="file1New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link2:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="file2New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link3:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="file3New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link4:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="file4New"
                />
              </div></div
          ></CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title" v-if="$store.state.selectedTeachingLog">
            <h3 class="text-3xl font-semibold">
              แก้ไขบันทึกการสอนหมายเลข:
              {{ $store.state.selectedTeachingLog.teaching_log_id }}
            </h3>
          </h6>
          <CButtonClose @click="modalEdit = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalEdit = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-info">Save</button>
        </template>
      </CModal>
    </form>
    <!-------------------MODAL ALERT------------------------>
    <CModal
      :show.sync="modalAlert"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="danger"
    >
      {{ msgAlert }}
      <template #header>
        <h6 class="modal-title">
          <h3 class="text-3xl font-semibold">คำเตือน</h3>
        </h6>
        <CButtonClose @click="modalAlert = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="modalAlert = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./SltSubject.vue";
import SltStudent from "./SltStudent.vue";
import ChkPeriod from "./ChkPeriod.vue";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    ChkPeriod,
  },
  data() {
    return {
      columns: [
        {
          label: "#",
          field: "screening_id",
          type: "number",
        },
        {
          label: "เวลา",
          field: "screening_created",
          type: "text",
        },

        {
          label: "ผลการพิจารณา",
          field: "screening_approval_teacher",
          type: "text",
        },
        {
          label: "จังหวัดที่ครอบครัวเดินทางมา",
          field: "screening_risk_family_province",
          type: "text",
        },
        {
          label: "จังหวัดที่ญาติเดินทางมา",
          field: "screening_risk_province_relatives",
          type: "text",
        },
        {
          label: "จังหวัดที่คนในหมู่บ้านเดินทางมา",
          field: "screening_risk_villager_province",
        },
        {
          label: "อาการบ่งชี้",
          field: "screening_symptom",
        },
        {
          label: "การเดินทางมาโรงเรียน",
          field: "screening_travel",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      rowsExcel: null,
      userData: this.$cookies.get("user"),
      improveInfo: null,
      levelInfo: null,
      routeInfo: null,
      selectedCiteria: null,
      selectedAppointment: null,
      selectedStartDate: null,
      selectedEndDate: null,
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
    };
  },
  mounted() {
    //console.log(this.userData);

    //FETCH ROUTE DROPDOWN
    this.axios
      .get(this.$hostUrl + "php_action/routeAPI.php?txtAction=getAllRoute")
      .then((response) => {
        this.routeInfo = response.data.mainData;
        //console.log(this.routeInfo);
      })
      .finally(() => {});

    //FETCH LEVEL DROPDOWN
    this.axios
      .get(this.$hostUrl + "php_action/levelAPI.php?txtAction=getAlleLevel")
      .then((response) => {
        this.levelInfo = response.data.mainData;
      })
      .finally(() => {});


    //FETCH SCREENING LOG BY STD
    this.axios
      .get(
        this.$hostUrl +
          "php_action/screeningAPI.php?txtAction=getSelfScreening&stdId=" +
          this.userData.stud_id+'&school_code='+this.userData.school_code
      )
      .then((response) => {
        console.log(response.data);
        this.$store.commit("setScreeningLogList", response.data.mainData);
        this.rowsExcel = response.data.excelData;
        //console.log(this.$store.state.teachingLogList);
      })
      .finally(() => {});
  },
  methods: {
    openModal(appId) {
      this.modalCreate = true;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/appointmentAPI.php?txtAction=getSingleAppointment&appId=" +
            appId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedAppointment = response.data.mainData;
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    createLog(event) {
      //SEND DATA
      if (!this.$store.state.selectedSubject) {
        alert("กรุณาเลือกรายวิชา");
        return false;
      }
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      formData.append("txtLessonSubjectID", 0);
      formData.append("txtSubjectClassID", 0);
      formData.append("txtSubLessonID", 0);
      formData.append("txtSubjectTechID", 0);
      formData.append("txtClassSchoolID", 0);
      formData.append("txtRoom", 0);
      if (this.$route.query.LessonSubjectID) {
        formData.append(
          "txtLessonSubjectID",
          this.$route.query.LessonSubjectID
        );
      }
      if (this.$route.query.SubjectClassID) {
        formData.append(
          "txtSubjectClassID",
          this.$route.query.SubjectClassID
        );
      }
      if (this.$route.query.SubLessonID) {
        formData.append(
          "txtSubLessonID",
          this.$route.query.SubLessonID
        );
      }
      if (this.$route.query.SubjectTechID) {
        formData.append(
          "txtSubjectTechID",
          this.$route.query.SubjectTechID
        );
      }
      if (this.$route.query.ClassSchoolID) {
        formData.append(
          "txtClassSchoolID",
          this.$route.query.ClassSchoolID
        );
      }
      if (this.$route.query.Room) {
        formData.append(
          "txtRoom",
          this.$route.query.Room
        );
      }

      //console.log(this.$store.state.selectedStudent);
      formData.append(
        "students",
        JSON.stringify(this.$store.state.selectedStudent)
      );
      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    updateLog(event) {
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      //console.log(this.$store.state.selectedStudent);
      formData.append(
        "students",
        JSON.stringify(this.$store.state.selectedStudent)
      );
      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },

    removeLog(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/teachingAPI.php?txtAction=delete&userId=" +
              this.userData.user_id +
              "&logId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    getAppByDate(event) {
      if (this.selectedStartDate == null || this.selectedEndDate == null) {
        this.msgAlert = "ต้องเลือกทั้งวันเริ่มต้น และวันสิ้นสุดของการค้นหา";
        this.modalAlert = true;
        return false;
      }
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getAppointmentByDate");
      formData.append("schoolId", this.userData.school_code);
      this.axios
        .post(this.$hostUrl + "php_action/appointmentAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.rowsExcel = response.data.excelData;
        })
        .finally(() => {});
    },
    openModalEdit(data) {
      this.modalEdit = true;
      this.axios
        .get(
          this.$hostUrl +
            "php_action/teachingAPI.php?txtAction=getTeachingLogByID" +
            "&logId=" +
            data
        )
        .then((response) => {
          let jsonSubject = {
            code: response.data.mainData.teaching_subject,
            label:
              response.data.mainData.subject_code +
              " " +
              response.data.mainData.subject_name,
          };
          let jsonPeriod = JSON.parse(response.data.mainData.teaching_periods);
          let jsonMissingStd = JSON.parse(
            response.data.mainData.teaching_std_missing
          );
          let jsonPicLink = JSON.parse(
            response.data.mainData.teaching_pictures
          );
          response.data.mainData.jsonPicLink = jsonPicLink;
          let jsonFileLink = JSON.parse(response.data.mainData.teaching_files);
          response.data.mainData.jsonFileLink = jsonFileLink;
          this.$store.commit("setSelectedTeachingLog", response.data.mainData);
          this.$store.commit("setSelectedSubject", jsonSubject);
          this.$store.commit("setCheckedPeriod", jsonPeriod);
          this.$store.commit("setSelectedStudent", jsonMissingStd);
          console.log(this.$store.state.selectedTeachingLog);

          if (this.$store.state.selectedTeachingLog) {
            this.pic1New = this.$store.state.selectedTeachingLog.jsonPicLink[0];
            this.pic2New = this.$store.state.selectedTeachingLog.jsonPicLink[1];
            this.pic3New = this.$store.state.selectedTeachingLog.jsonPicLink[2];
            this.pic4New = this.$store.state.selectedTeachingLog.jsonPicLink[3];
            this.file1New =
              this.$store.state.selectedTeachingLog.jsonFileLink[0];
            this.file2New =
              this.$store.state.selectedTeachingLog.jsonFileLink[1];
            this.file3New =
              this.$store.state.selectedTeachingLog.jsonFileLink[2];
            this.file4New =
              this.$store.state.selectedTeachingLog.jsonFileLink[3];
          }
        })
        .finally(() => {});
    },
    uploadFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uppoaded");
            this.pictUrl1 = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uppoaded");
            this.pictUrl2 = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uppoaded");
            this.pictUrl3 = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uppoaded");
            this.pictUrl4 = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uppoaded");
            this.fileLink1 = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uppoaded");
            this.fileLink2 = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uppoaded");
            this.fileLink3 = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uppoaded");
            this.fileLink4 = this.$hostFileUrl + response.data.message.file8Url;
          }
        })
        .finally(() => {});
    },
    editFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uploaded");
            this.pic1New = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uploaded");
            this.pic2New = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uploaded");
            this.pic3New = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uploaded");
            this.pic4New = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uploaded");
            this.file1New = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uploaded");
            this.file2New = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uploaded");
            this.file3New = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uploaded");
            this.file4New = this.$hostFileUrl + response.data.message.file8Url;
          }
        })
        .finally(() => {});
    },
  },
};
</script>
